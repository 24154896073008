.textarea {
    position: relative;

    &__input {
        border: 1px solid $gray;

        &:focus {
            border-color: $brand-primary;
            outline: 0;
        }
    }

    &__input,
    &__placeholder {
        padding: 1rem;
        resize: none;
    }

    &__placeholder {
        @include coverall;
        pointer-events: none;
        color: $gray;
    }
}
