.badge {
    display: inline-block;
    border-radius: 3px;
    padding: 0.25rem 0.5rem;
    font-size: 0.625rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 500;
    -webkit-print-color-adjust: exact;

    &--primary {
        background-color: $brand-primary;
        color: #fff;
    }
    &--success {
        background-color: $brand-green;
        color: #fff;
    }
}
