// Form Component

form {
    .req {
        position: relative;
        top: -0.125em;
        line-height: 0.5em;
        padding-left: 0.125em;
        color: $state-error;
    }
}

.reduxform {
    &__fields {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    &__footer {
        display: flex;
        align-items: flex-start;
        padding-top: 1rem;
    }
    &__error {
        color: $state-error;
        padding: 1rem 0;
    }
    &__feedback {
        margin-left: 1rem;
    }
}

.formwidget {
    width: 100%;
    background-color: #fff;
    border: 1px solid $gray-light;
    box-shadow: $box-shadow-elevated;
    max-width: #{map-get($container-max-widths, 'lg')};
    min-height: 200px;
    padding: 1rem;
    margin: 4rem auto 2rem;

    &--small {
        max-width: 460px;
        text-align: center;
    }

    &__title {
        font-size: 1.5rem;
        font-weight: bold;
        margin: 1rem 0;
    }

    &__alertcontainer {
        max-width: 50rem;
        margin: 0 auto;

        &:empty {
            display: none;
        }
    }

    &__smallprint {
        padding: 1rem 0;
        font-size: 0.875rem;

        a {
            color: $gray;
            text-decoration: underline;
        }
    }
}
