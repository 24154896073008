.statuswidget {
    @include card();
    text-align: center;
    max-width: 640px;
    margin: 0 auto;
    padding: 2rem;

    &__icon {
        font-size: 3rem;
        margin-bottom: 0.5rem;

        .icon--warning {
            color: $state-error;
        }
        .icon--check {
            color: $state-success;
        }
    }
    &__title {
        margin-bottom: 1rem;
    }
    &__content {
        max-width: 540px;
        margin: 0 auto;

        > * + * {
            margin-top: 1rem;
        }
    }
}
