.card {
    @include card;
    flex: 1 0 auto;
    scroll-margin-top: var(--header-height);

    &__header {
        display: flex;
        align-items: flex-start;
        padding: 1rem;
    }
    &__title {
        font-size: 1.3125rem;
    }
    &__description {
        margin-top: 0.25rem;
    }
    &__icon {
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        border-radius: 50%;
        background-color: $gray-light;
    }
    &__actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1rem;
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 1px solid $gray-light;
    }
}

.card--padded {
    .card__content {
        padding: 0 1rem 1rem;
    }
}

.card--flat {
    box-shadow: 1px 0 0 0 $gray-light, 0 1px 0 0 $gray-light,
        1px 1px 0 0 $gray-light, 1px 0 0 0 $gray-light inset,
        0 1px 0 0 $gray-light inset;
}

.card + .card {
    margin-top: var(--spacing-y);
}
