$slider-track-height: 8px;
$slider-thumb-size: 22px;

.MuiSlider {
    &-root {
        // color: $gray-dark;
        height: $slider-track-height;
        width: calc(100% - #{$slider-thumb-size});
        margin-left: $slider-thumb-size / 2;
        margin-right: $slider-thumb-size / 2;
    }

    &-track,
    &-rail {
        height: $slider-track-height;
        border-radius: $slider-track-height / 2;
    }

    &-thumb {
        height: $slider-thumb-size;
        width: $slider-thumb-size;
        margin-top: -$slider-track-height;
        margin-left: -$slider-thumb-size / 2;
        background-color: #fff;
        border: 2px solid currentColor;
    }

    &-mark {
        display: none;
    }

    &-valueLabel {
        left: calc(-50% + 2px);

        [class^='PrivateValueLabel-label'] {
            color: #fff;
        }
    }
}

// negtive variation
.MuiSlider-root.is-negative {
    color: $value-low;

    .MuiSlider-rail {
        background-color: $brand-primary;
    }
}
