.criteriagroup-index {
    &__item {
        display: grid;
        gap: 1rem;
        margin-top: 1rem;

        @include mq(lg) {
            grid-template-columns: 1fr 1fr;
        }
    }
    &__link {
        display: flex;
        align-items: center;
        line-height: 1.3;
    }
    &__score {
        flex: none;
        margin-right: 1rem;
    }
    &__title {
        display: block;
        font-weight: bold;
        text-decoration: underline;
    }
    &__meta {
        color: $gray;
    }
}
