.holdings {
    &__content {
        margin-bottom: 1rem;
    }
    &__footer {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: space-between;
        font-size: 0.75rem;
    }
    &__disclaimer {
        color: $gray;
    }
}
