.pdf {
    &__page {
        page-break-after: always;
    }
    &__header {
        position: relative;
        margin-bottom: 0.5cm;
    }
    &__logo {
        position: absolute;
        right: 0;
        top: 0;
    }

    @media screen {
        &__page {
            font-size: 12px;
            max-width: 900px;
            border: 1px solid $gray;
            background-color: #fff;
            margin: 1rem;
            padding: 2rem;
        }
    }
}

// Overwrite Screen Styles
.pdf {
    .report {
        &__title {
            font-size: 2rem;
            max-width: none;
            border-bottom: 1px solid $gray;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
        }
        &__timestamp {
            &::before {
                content: '\00B7';
                text-align: center;
                display: block;
                width: 1.5rem;
            }
        }
    }
    .reportsection {
        margin-top: 1rem;
        &__title {
            font-size: 1.5rem;
        }
        &__subtitle,
        &__icon {
            display: none;
        }

        .grid__item--half {
            border-bottom: 1px solid #d7d8d9;

            & + .grid__item--half {
                border-left: 1px solid #d7d8d9;
            }
        }
    }
    .table--criteria-ratings {
        table-layout: fixed;

        .col--criterion {
            width: 44%;

            &.half {
                width: 32%;
            }
        }
        .col--category {
            width: 12%;
        }
        .col--scale {
            width: 20%;
        }
        .col--titles,
        .col--share,
        .col--rating {
            width: 12%;
        }
    }
}
