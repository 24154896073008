.isinchecker {
    &__form > * + * {
        margin-top: 1rem;
    }

    &__footer {
        @include mq(md) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
    }

    &__modebtn {
        color: $gray-dark;
        background-color: transparent;

        &:hover {
            background-color: $gray-lighter;
        }

        &--active {
            color: darken($brand-primary-dark, 10%);
            background-color: rgba($brand-primary, 0.1) !important;
            box-shadow: 0 0 0 1px $brand-primary;
        }
    }

    &__filefield,
    &__textfield {
        display: flex;
        flex-direction: column;
        height: 200px;
    }

    &__fileupload,
    &__filecontents {
        flex: 1 0 0%;
    }

    &__filecontents {
        @include scrollable;
        border: 1px solid $gray;
    }

    &__results {
        width: 100%;

        td,
        th {
            padding: 0.25rem;
            vertical-align: middle;

            &:first-child {
                padding-left: 1rem;
            }
            &:last-child {
                padding-right: 1rem;
            }
        }

        tbody tr:nth-of-type(odd) {
            background-color: rgba($gray-light, 0.5);
        }
    }

    &__result {
        &--found .isinchecker__found {
            color: $state-success;
        }
        &--notfound {
            color: $state-error;
        }
        &--notincluded {
            color: lighten($gray, 10%);
        }
    }

    &__isin {
        width: 12em;
        letter-spacing: 1px;
    }

    &__found {
        user-select: none;
        display: flex;
        align-items: center;
    }

    &__count {
        margin-right: auto;
    }

    &__error {
        padding-top: 0.5rem;
        color: $state-error;

        .icon {
            margin-right: 0.5rem;
        }
    }
}
