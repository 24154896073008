.demo-metrics {
    position: relative;
    user-select: none;

    &__rating {
        display: flex;
        align-items: center;
        margin: -2.5rem 0 0 30%;

        .circular-progress {
            @include mq(lg) {
                width: 150px;
                height: 150px;
                font-size: 2rem;
            }
        }
    }
    &__ratinglabel {
        font-weight: 500;
        font-size: 1.25rem;
        padding-left: 1rem;
    }

    &__table {
        position: relative;
        margin-bottom: 1rem;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -1px;
            left: -1px;
            right: -1px;
            height: 70px;
            background: linear-gradient(
                to bottom,
                rgba(white, 0) 0%,
                rgba(white, 1) 90%
            );
        }
    }
    &__namecol {
        .icon {
            margin-right: 0.25em;
        }
    }
    &__valuecol {
        width: 200px;
    }

    .labelled-valuebar__bar {
        display: block;
    }
}

.demo-metrics {
    // value bars progress
    .valuebar__bar {
        transform-origin: 0 50%;
        transform: scaleX(0);
        transition: transform 500ms ease;
    }
    @for $i from 0 to 6 {
        tbody tr:nth-child(#{$i + 1}) .valuebar__bar {
            transition-delay: #{$i * 100}ms !important;
        }
    }

    // circular bar progress
    .circular-progress__bar {
        stroke-dasharray: 0 100;
        transition: stroke-dasharray 700ms ease;
        transition-delay: 500ms;
    }

    // ratin "Nachhaltig"
    &__ratinglabel {
        opacity: 0;
        transform: translateX(-15px);
        transition: opacity 0.45s ease, transform 0.65s ease-out;
        transition-delay: 900ms;
    }
}
.demo-metrics.in-view {
    .valuebar__bar {
        transform: scaleX(1);
    }
    .circular-progress__bar {
        stroke-dasharray: 80 100;
    }
    .demo-metrics__ratinglabel {
        opacity: 1;
        transform: translateX(0);
    }
}
