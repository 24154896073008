.MuiAccordionDetails {
    &-root {
        display: block;

        > * + * {
            margin-top: 1rem;
        }
    }
}

.MuiIconButton {
    &-label {
        font-size: 1.25rem;
    }
}
