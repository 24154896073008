.coverage-results {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-top: 3rem;

    &__graph {
        flex: none;
    }
    &__content {
        flex: 1 0 0%;
    }
}
