// Table Component

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    border: 1px solid $gray-light;

    tbody {
        border: 1px solid $gray-light;
    }

    tr {
        background-color: #fff;

        &[disabled] {
            color: rgba($body-color, 0.45);
        }
    }

    td,
    th {
        @include hyphenate;
        padding: 0.25rem 0.5rem;
        border-bottom: 1px solid $gray-light;
        border-left: 1px solid $gray-light;
        vertical-align: middle;
    }

    td {
        font-variant-numeric: tabular-nums;
    }

    th {
        font-size: 0.875rem;
        font-family: $font-family-display;
        font-weight: 700;
        text-transform: uppercase;
        color: $gray-darker;
    }

    thead th {
        padding-bottom: 0.5rem;
    }

    tbody tr:nth-of-type(odd) {
        background-color: rgba($gray-light, 0.5);
    }

    code {
        white-space: nowrap;
    }

    button {
        font-size: 1em;
    }
}

.table--alignright {
    td:not(:first-child),
    th:not(:first-child) {
        text-align: right;
    }
    th[scope='row'] {
        text-align: left;
    }
}

.table--responsive {
    @media screen and (max-width: 640px) {
        border: 0;

        thead {
            display: none;
        }
        tbody {
            border: 0;
        }

        tr,
        td {
            display: block;
            text-align: left !important;
        }

        tr {
            border-top: 1px solid $gray-light;
            + tr {
                margin-top: 2rem;
            }
        }

        th[scope='row'] {
            display: block;
            width: 100%;
            border-right: 1px solid $gray-light;
        }

        td {
            display: flex;
            align-items: center;
            border-left: 0;
        }

        td[label]::before {
            @include truncate;
            display: inline-block;
            content: attr(label);
            font-weight: 500;
            flex: 0 0 45%;
            max-width: 45%;
        }
    }
}

.table--funddata {
    @include mq(md) {
        table-layout: fixed;

        th {
            width: 30%;
        }
        td {
            width: 70%;
        }
    }
}

.table--criteria-ratings {
    @include mq(md) {
        table-layout: fixed;

        .col--criterion {
            width: 42%;

            &.half {
                width: 32%;
            }
        }
        .col--category {
            width: 10%;
        }
        .col--scale {
            width: 20%;
        }
        .col--titles,
        .col--share {
            width: 10%;
        }

        .col--rating {
            width: 12%;
        }
        .col--details {
            width: 80px;
        }
    }
}

.table--sustainability-profile {
    tbody td {
        height: 42px; // height of MUI Switch
    }

    @include mq(md) {
        table-layout: fixed;

        .col--active {
            width: 60px;
        }
        .col--title {
            width: 50%;
        }
        .col--edit {
            width: 145px;
        }
    }
}

.table-scroll-container {
    @media screen and (max-width: 767px) {
        width: 100%;
        overflow-x: scroll;

        > .table {
            min-width: 800px;
        }
    }
}
