.nav {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 500;

    &__user {
        display: flex;
        align-items: center;

        > * + * {
            margin-left: 1rem;
        }
    }
    &__toggle {
        @include button-reset;
        display: block;
        padding: 1rem;
        margin-right: -1rem;
    }
    &__item {
        display: block;
        position: relative;
    }
    &__link {
        display: block;
        padding: 1rem;
        color: inherit;
    }
    &__subtoggle {
        @include button-reset;
        display: flex;
        width: 100%;
        padding: 1rem;
        color: inherit;
        text-align: left;
    }
    &__link,
    &__subtoggle {
        &:hover {
            color: inherit;
            background-color: $gray-lightest;
        }
    }
    &__submenu-label {
        display: block;
        flex: 1 0 auto;
    }
    &__submenu-indicator {
        display: block;
        position: relative;
        transform: rotate(0);
        transition: transform 0.25s $animation-curve-default;

        .nav__item--with-submenu.is-open & {
            transform: rotate(-180deg);
        }
    }

    @include mq-down(lg) {
        &__container {
            @include scrollable;
            display: none;

            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;

            border-top: 1px solid $gray-light;
            background-color: #fff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

            .nav-open & {
                display: block;
            }
        }
        &__menu {
            display: block;
        }
        &__item {
            border-bottom: 1px solid $gray-light;

            &--with-submenu.is-open {
                background-color: $gray-lightest;
            }
        }
        &__user {
            order: -1;
            padding-right: 1rem;
        }
    }
    @include mq(lg) {
        &__container,
        &__menu {
            display: flex;
        }
        &__menu {
            border-right: 1px solid $gray-light;
            padding-right: 1rem;
            margin-right: 1rem;
        }
        &__toggle {
            display: none;
        }
        &__subtoggle {
            padding-right: 0.5rem;
        }
        &__item {
            display: flex;

            &--with-submenu:hover,
            &--with-submenu.is-open {
                .subnav {
                    display: block;
                }
            }
        }
    }
}
