// A fund analysis report

.report {
    &__header {
        margin-bottom: 2rem;
    }
    &__title {
        max-width: 40ch;
    }
    &__isin {
        display: inline-block;
    }
    &__meta {
        margin-top: 0.5rem;
        line-height: 1.8;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @media screen and (max-width: 640px) {
            display: block;
        }
    }
    &__timestamp {
        display: flex;
        align-items: center;

        @media (min-width: 640px) {
            &::before {
                content: '\00B7';
                text-align: center;
                display: block;
                width: 1.5rem;
            }
        }

        .timestamp {
            color: inherit;
            font-size: 1em;
        }

        .badge {
            margin-left: 0.5rem;
        }
    }
    &__coverage {
        flex-basis: 100%;
    }
    &__disclaimer {
        color: $gray;
        margin-top: 2rem;
    }
    &__grouplink {
        display: inline-block;
        color: $gray-dark;
        text-decoration: underline;
        margin-left: 0.5rem;

        @include hover-focus {
            color: $gray-dark;
            text-decoration: none;
        }
    }
    &__pdflink {
        display: flex;
        align-items: center;

        a {
            display: inline-flex;
            align-items: center;
            gap: 0.25rem;
        }

        .spinner {
            --spinner-size: 28px;
        }

        @include mq(md) {
            margin-left: 1rem;
        }
    }
}

.reportsection {
    position: relative;
    margin-top: 2rem;
    scroll-margin-top: var(--header-height);

    &__header {
        display: flex;
        align-items: flex-start;
    }
    &__title {
        @include hyphenate;
        font-size: 2rem; //32px
        margin-bottom: 1rem;
    }
    &__cta {
        p {
            margin-bottom: 0.5rem;
        }
        @include mq(lg) {
            display: flex;
            justify-content: space-between;

            p {
                margin-bottom: 0;
                margin-right: 1rem;
            }
        }
    }
    &__subtitle {
        margin-top: -1rem;
        margin-bottom: 1rem;
    }
    &__icon {
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        border-radius: 50%;
        background-color: $gray-light;
    }
}
