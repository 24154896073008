// Header Component

.header {
    position: relative;
    z-index: z('header');
    background-color: #fff;
    box-shadow: $box-shadow;

    &__inner {
        height: var(--header-height);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem var(--spacing-x);
    }

    &__brand {
        display: block;
        width: 100px;

        .logo {
            width: 100%;
            height: auto;
            transform: translateY(3px);
        }
    }
}
