.riskscale {
    display: block;

    &__title {
        text-align: center;
        margin-bottom: 1rem;
    }
    &__content {
        display: flex;
        // flex-wrap: wrap;
        font-size: 0.875rem;
    }
    &__description {
        display: none;
        margin: 0;
    }
    &__list {
        display: flex;
        flex-basis: 100%;
        justify-content: center;
        align-items: center;
    }
    &__indicator {
        @include flex-center;
        flex: 1 0 1rem;
        max-width: 5rem;
        text-align: center;
        padding: 0.35rem;
        background-color: $gray-lightest;
        position: relative;
        z-index: 1;
        user-select: none;

        &--active {
            @include traffic-light-states-inverted(
                '.riskscale',
                background-color
            );
            color: #fff;
            padding: 0.5rem;
            font-size: 1rem;
            border-color: transparent;
            z-index: 2;
        }
    }

    @include mq(md) {
        &__description {
            display: block;

            &--low {
                text-align: right;
            }
            &--high {
                text-align: left;
            }
        }
        &__list {
            position: relative;
            flex: 1 0 auto;
            padding: 0 1rem;

            &::after {
                content: '';
                display: block;
                height: 1px;
                position: absolute;
                left: 1rem;
                right: 1rem;
                top: 50%;
                background-color: $gray;
            }
        }
    }
}
