.react-tabs {
    -webkit-tap-highlight-color: transparent;

    &__tab-list {
        font-weight: 500;
        border-bottom: 1px solid $gray;
        padding: 0;

        .icon {
            margin-right: 0.25rem;
        }
    }

    &__tab {
        display: inline-flex;
        align-items: center;
        border: 1px solid transparent;
        border-bottom: none;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 0.5rem 1rem;
        color: darken($gray, 20%);
        cursor: pointer;

        &--selected {
            background: $gray-lightest;
            border-color: $gray;
            color: darken($brand-primary, 5%) !important;
        }

        &--disabled {
            color: GrayText;
            cursor: default;
        }

        &:hover {
            color: $body-color;
        }

        &:focus {
            outline: none;

            &:after {
                content: '';
                position: absolute;
                height: 5px;
                left: -4px;
                right: -4px;
                bottom: -5px;
                background: $gray-lightest;
            }
        }
    }

    &__tab-panel {
        display: none;
        padding-bottom: 1rem;

        &--selected {
            display: block;
        }
    }

    @include mq-down(md) {
        &__tab {
            display: flex;
            border: 1px solid darken($gray-light, 3%);
            padding: 0.75rem 1rem;
            bottom: auto;

            &--selected {
                z-index: 1;
                background-color: rgba($brand-primary, 0.15);
                border-color: lighten($brand-primary, 5%);
            }

            &:focus::after {
                display: none;
            }
        }
        &__tab-list {
            border-bottom: 0;
        }
    }
}
