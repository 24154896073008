//--------------------
// SCSS VARIABLES
//--------------------

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
    xs: 0,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
    xxl: 1400px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    md: 720px,
    lg: 960px,
    xl: 1180px
);

// Z-Index Stack
//
// Control the order of layers in the application
$z-layers: (
    'modal': 999,
    'messages': 200,
    'header': 50,
    'sidebar': 40,
    'content-overlay': 25
);

// Colors
//
// Grayscale.

$gray-darkest: #282737;
$gray-darker: #323232;
$gray-dark: #737190;
$gray: #a0a0a0;
$gray-light: #e8f1fa;
$gray-lighter: #f6f6f4;
$gray-lightest: #fbfbfb;

// Brand Colors

$brand-cyan: #49abae;
$brand-green: #259078;
$brand-yellow: #f7da61;
$brand-red: #f95368;
$brand-beige: #f6f6f4;
$brand-blue: #e8f1fa;

// Value Colors
$value-high: $brand-green;
$value-medium: $brand-yellow;
$value-low: $brand-red;

// State Colors

$state-success: $brand-green;
$state-info: $gray-light;
$state-warning: $brand-yellow;
$state-error: $brand-red;

//Color Mappings

$brand-primary: $brand-cyan;
$brand-primary-dark: darken($brand-cyan, 6%);

// Links
//
// Style anchor elements.

$link-color: $brand-primary;
$link-decoration: none;
$link-hover-color: $brand-primary-dark;
$link-hover-decoration: none;

// Body
//
// Settings for the `<body>` element.

$body-bg: #fff;
$body-color: $gray-darkest;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-base: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;

$font-family-display: 'IBM Plex Sans', sans-serif;

$font-weight-base: normal;
$font-size-base: 1rem;
$line-height-base: 1.5;

// Animation
//
// Default easing curves, Transitions, etc

$animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
$animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
$animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
$animation-curve-default: $animation-curve-fast-out-slow-in;

// Misc Shared
//
// common shared styles
$border-radius: 0.25rem;
$box-shadow: 0 2px 16px rgba(0, 0, 0, 0.15);
$box-shadow-elevated: 0 6px 12px 0 rgba(0, 0, 0, 0.1);

// Components
//
// Variables related to specific elements
