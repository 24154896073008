.sidebar {
    position: relative;
    width: var(--sidebar-width);
    height: 100vh;
    padding-top: var(--header-height);
    background-color: $gray-darkest;

    &__nav {
        padding-top: var(--spacing-y);

        @include mq(lg) {
            padding-top: calc(var(--spacing-y) - 1rem);
        }
    }
    &__item {
        position: relative;

        &--with-divider {
            padding-bottom: 1rem;
            margin-bottom: 1rem;
            border-bottom: 1px solid rgba($gray, 0.2);
        }
    }
    &__link {
        position: relative;
        display: flex;
        align-items: center;
        padding: 1rem var(--spacing-x);
        color: rgba(#fff, 0.85);

        @include hover-focus {
            color: #fff;

            .icon {
                color: #fff;
            }
        }

        &--active {
            color: #fff;

            .icon {
                color: $brand-primary !important;
            }
        }
    }
    &__icon {
        margin-right: 1rem;
        color: $gray-dark;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) and (max-width: map-get($grid-breakpoints, 'xxl') - 1) {
        &__label {
            position: absolute;
            left: 100%;
            top: 50%;
            max-width: 150px;
            white-space: nowrap;
            font-size: 0.875rem;
            padding: 0.5rem;
            background-color: $gray-darkest;
            border-radius: 3px;
            transform: translate(-20px, -50%);
            pointer-events: none;
            opacity: 0;
            transition: opacity 0.15s ease, transform 0.15s ease;
        }
        &__link:hover > .sidebar__label,
        &__link:focus > .sidebar__label {
            opacity: 1;
            transform: translate(-15px, -50%);
        }
    }
}
