%richtext {
    > * + * {
        margin-top: 1em;
    }

    a:not(.btn) {
        @include hyphenate;
    }

    strong,
    b {
        font-weight: 700;
    }

    em,
    i {
        font-style: italic;
    }

    ul,
    ol {
        list-style-position: outside;
        line-height: 1.5;
        padding-left: 1.5rem;

        li {
            padding: 0.1em 0.25rem;

            &::marker {
                color: $brand-primary;
                font-weight: 700;
            }
        }
    }

    ul {
        list-style-type: disc;
    }

    ol {
        list-style-type: decimal;
    }

    blockquote {
        font-size: 1.25em;
        font-style: italic;

        &:after {
            content: close-quote;
            vertical-align: bottom;
        }

        &:before {
            content: open-quote;
            vertical-align: top;
        }
    }
}

.richtext {
    @extend %richtext;
}

.richtext--checkmarklist {
    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            padding-left: 2em;
            position: relative;

            &::before {
                content: '';
                display: block;
                width: 1.5em;
                height: 1.5em;
                color: $brand-primary;
                position: absolute;
                left: 0;
                top: 0;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%2349abae' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E%0A");
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }
        }
    }
}
