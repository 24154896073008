.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &__btn {
        @include button-reset;
        display: flex;
        align-items: center;

        &:disabled {
            opacity: 0.4;
            pointer-events: none;
        }
    }
    &__pages {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        @include mq-down(md) {
            order: 1;
            flex-basis: 100%;
        }
    }
}
