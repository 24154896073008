.filterpanel {
    --filterpanel-width: 100%;

    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: #fff;
    box-shadow: $box-shadow-elevated;
    bottom: 0;
    left: 0;
    top: 0;
    width: var(--filterpanel-width);
    z-index: z('header') + 1;
    transition: transform 0.25s $animation-curve-default;
    transform: translate(0, 110%);

    @include mq(lg) {
        --filterpanel-width: 500px;

        transform: translate(-110%, 0);
        right: auto;
        border-right: 1px solid $gray;
    }

    @include mq(xxl) {
        --filterpanel-width: 605px;
    }

    &__header,
    &__footer {
        display: flex;
        align-items: center;
        padding: 1rem;
    }
    &__header {
        justify-content: space-between;
        border-bottom: 1px solid $gray;
    }
    &__footer {
        border-top: 1px solid $gray;
    }

    &__main,
    &__form {
        width: 100%;
        display: flex;
        flex: 1 0 0%;
        overflow: hidden;
    }

    &__column {
        @include scrollable;
        flex: 0 0 var(--filterpanel-width);
        max-width: var(--filterpanel-width);
        transform: translateX(0);
        transition: transform 0.15s $animation-curve-default;
        padding: var(--spacing-y) var(--spacing-x);
    }

    &__title {
        font-size: 1rem;
    }

    &__close,
    &__back {
        @include button-reset;
    }

    &__back {
        position: relative;
        visibility: hidden;

        &__label {
            position: absolute;
            left: 100%;
            top: 1px;
        }
    }
}

.filterpanel--open {
    transform: translate(0) !important;
}
.filterpanel--selected {
    .filterpanel__column {
        transform: translateX(-100%);
    }
    .filterpanel__back {
        visibility: visible;
    }
}
