.onboarding {
    &__stepnav {
        margin-bottom: 1rem;
        @include mq(lg) {
            margin-bottom: 2rem;
        }
    }

    &__steptitle {
        text-align: center;
    }

    &__stepcontent {
        padding: 1.5rem 0;
    }

    &__action {
        @include mq(lg) {
            display: flex;
            justify-content: center;
            gap: 1rem;
        }
    }

    &__customerform {
        max-width: 820px;
        margin: 0 auto;

        .reduxform__fields {
            @include card();
            padding: 1rem;
            margin-bottom: 1.5rem;
        }
    }

    &__summary {
        margin-bottom: 1rem;
    }

    &__summaryheader {
        h3,
        h4 {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $gray-light;
            padding-bottom: 0.25rem;
            margin-bottom: 0.25rem;

            .icon {
                margin-right: 0.5rem;
            }
        }
        margin-bottom: 1rem;
    }
}
