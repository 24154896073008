$comparison-scale-height: 25px;

.comparison {
    margin: 1.5rem 0 1rem;
    font-size: 11px;

    &__scale {
        position: relative;
        display: flex;
        border: 1px solid $gray;
        height: $comparison-scale-height;
    }
    &__lower,
    &__equal,
    &__higher {
        flex: 1 1 auto;
        text-align: center;
        line-height: $comparison-scale-height;
        white-space: nowrap;
        padding: 0 0.125rem;
        -webkit-print-color-adjust: exact;
    }
    &__lower {
        background-color: darken($gray-light, 4%);
    }
    &__equal {
        background-color: $gray-light;
    }
    &__higher {
        background-color: #fff;
    }
    &__position {
        position: relative;
        width: 1px;
        flex: 0 0 1px;
        background-color: $gray-dark;

        &::before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 9.5px 5.5px 0 5.5px;
            border-color: #000000 transparent transparent transparent;
            transform: translate(-5px, -12px);
        }
    }
    &__labels {
        display: flex;
        justify-content: space-between;
        padding-top: 0.25rem;
    }
}
