.creditbalance {
    position: relative;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 5px solid $gray;
    padding: 1rem;
    background-color: $gray-light;

    &__available {
        font-size: 1.25rem;
        font-weight: 700;

        .creditbalance--highlighted & {
            color: $state-error;
        }
    }
    &__bar {
        position: absolute;
        top: -5px;
        left: 0;
        height: 5px;
        background-color: $brand-primary;
    }
}
