.coverup {
    position: relative;
    pointer-events: none;
    user-select: none;

    &::after {
        @include coverall;
        content: '';
        display: block;
        z-index: z('content-overlay');
        background: linear-gradient(
            180deg,
            rgba(#fff, 0) 0%,
            rgba(#fff, 1) 90%
        );
    }
}
