:root {
    --spacing-x: 1rem;
    --spacing-y: 1rem;
    --section-spacing: 2rem;

    --header-height: 60px;

    @include mq(md) {
        --spacing-x: 2rem;
        --spacing-y: 2rem;
        --section-spacing: 4rem;
    }
}
