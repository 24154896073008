.fundlist {
    opacity: 1;
    transition: opacity 0.05s linear;

    &--disabled {
        pointer-events: none;
        transition-delay: 0.25s;
        opacity: 0.5;
    }

    &__headercol {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__header {
        min-height: 3.125rem; //50px;
        font-size: 0.875rem;
    }
    &__item:not(:last-child) {
        margin-bottom: 1rem;
    }

    &__actions {
        display: flex;
        align-items: center;
    }
    &__actionselect {
        max-width: 8rem;
        margin-left: 0.5rem;
    }
    &__orderselect {
        max-width: 11rem;
    }

    &__resultcount {
        text-align: right;
        min-width: 9em;
    }

    @include mq(md) {
        &__header {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
        }
        &__orderselect {
            margin-left: 0.5rem;
            margin-right: 2rem;
        }
    }
}
