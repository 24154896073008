// Main Site Layout

body {
    overflow-x: hidden;
    height: 100%;

    &.no-scroll {
        overflow: hidden;
    }
}

// Default Layout

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    min-height: 100vh;
    position: relative;

    .header,
    .footer {
        flex: none;
    }

    .main {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;

        &::after {
            content: '';
            display: block;
            height: 0px;
            visibility: hidden;
        }
    }
}

.container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--spacing-x);
    padding-right: var(--spacing-x);

    @include make-container-max-widths();
}

.content-width-narrow {
    max-width: 50rem !important;
}
