// Main Typography Styles

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    font-family: $font-family-display;
    font-weight: 600;
    letter-spacing: -0.03em;
    line-height: 1.3;
}

h1,
.h1 {
    font-size: 2.5rem; //40px
}

h2,
.h2 {
    font-size: 2.25rem; //36px
}

h3,
.h3 {
    font-size: 1.3125rem; // 21px
}

// Lead Paragraph

.lead {
    font-size: 1.25rem;
}

.capitals {
    font-family: $font-family-display;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}

.bullet-list {
    list-style-type: disc;
    padding-left: 1rem;
}

.timestamp {
    color: $gray;
    font-size: 0.75rem;
}

// Hyphenation

.hyphenate {
    @include hyphenate;
}

// Truncation

.truncate {
    @include truncate;
}
