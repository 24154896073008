.section {
    position: relative;
    padding: var(--section-spacing) 0;

    &__title {
        margin-bottom: 1rem;
    }
    &__text {
        margin-bottom: 1rem;
        line-height: 1.75;
        @include mq(md) {
            font-size: 1.125rem;
        }
    }
    &__image {
        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.section--blue {
    background-color: $brand-blue;
}
.section--beige {
    background-color: $brand-beige;
}
.section--white {
    background-color: #fff;
}
.section--centered {
    @include mq(md) {
        text-align: center;
    }
}
.section--2col {
    .section__inner {
        display: grid;
        gap: var(--section-spacing);

        @include mq(lg) {
            grid-template-columns: 1fr 1fr;
            align-items: center;
        }
    }
}
.section--reverse {
    @include mq(lg) {
        .section__content {
            order: -1;
        }
    }
}
