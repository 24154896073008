.search {
    display: grid;
    gap: var(--spacing-y);

    &__results {
        position: relative;
        min-height: 200px;

        > .spinner {
            position: absolute;
            left: 50%;
            top: 5rem;
            transform: translateX(-50%);
            z-index: z('content-overlay');
        }
    }

    &__toggle {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        text-align: left;
        padding: 0.75rem 1rem;
        border-radius: 0;

        .icon {
            margin: 0;
        }

        &__text {
            font-size: 1rem;
            flex: 1 0 auto;
        }
        &__indicator {
            font-size: 1.25rem;
            transform: rotate(0);
            transition: transform 0.15s ease;

            &--active {
                transform: rotate(-180deg);
            }
        }
    }

    &__pagination {
        margin-bottom: 1.5rem;
    }
    &__disclaimer {
        color: $gray;
        line-height: 1.2;
    }

    @include mq(lg) {
        grid-template:
            'filter searchbar' auto
            'filter results' 1fr
            '. footer' auto / 250px 1fr;

        &__bar {
            grid-area: searchbar;
        }
        &__filter {
            grid-area: filter;
            display: flex;
            flex-direction: column;
        }
        &__results {
            grid-area: results;
        }
        &__footer {
            grid-area: footer;
        }
    }
    @include mq(xxl) {
        grid-template-columns: 300px 1fr;
    }
}
