.valuebar {
    display: block;
    position: relative;
    height: 8px;
    background-color: $gray-light;
    border-radius: 10em;
    overflow: hidden;
    -webkit-print-color-adjust: exact;

    &__bar {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: $brand-primary;
        -webkit-print-color-adjust: exact;

        .valuebar--positive & {
            background-color: $value-high;
        }
        .valuebar--negative & {
            background-color: $value-low;
        }
        .valuebar--disabled & {
            background-color: $gray !important;
        }
    }
}

.labelled-valuebar {
    display: flex;
    align-items: center;
    font-variant-numeric: tabular-nums;

    &__value {
        flex: 1 0 5ch;
        min-width: 5ch;
        text-align: right;
        white-space: nowrap;
    }

    &__bar {
        display: none;
        margin-right: 0.5rem;
        flex: 100 0 100px;

        @include mq(lg) {
            display: block;
        }
    }
}
