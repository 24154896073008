.grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: var(--spacing-y);

    &--nogap {
        gap: 0;
    }

    &__item {
        display: flex;
        flex-direction: column;
        grid-column: 1/-1;
    }

    @media print {
        > .grid__item {
            &--half {
                grid-column: span 3;
            }
            &--third {
                grid-column: span 2;
            }
        }
    }
}

.grid--md {
    > .grid__item {
        @include mq(md) {
            &--half {
                grid-column: span 3;
            }
            &--third {
                grid-column: span 2;
            }
            &--twothirds {
                grid-column: span 4;
            }
        }
    }
}

.grid--lg {
    > .grid__item {
        @include mq(lg) {
            &--half {
                grid-column: span 3;
            }
            &--third {
                grid-column: span 2;
            }
            &--twothirds {
                grid-column: span 4;
            }
        }
    }
}
