.searchfield {
    background-color: #fff;
    display: block;
    position: relative;
    text-align: left;

    &__input {
        display: block;
        width: 100%;
        height: 3.125rem;
        font-size: 1rem;
        padding-left: 3rem;
        padding-right: 1rem;
        outline: 0 !important;
        border: 1px solid $gray;

        &:focus {
            border-color: $gray-dark;
            box-shadow: 0 0 0 1px $gray-dark;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);
        user-select: none;
    }
}
