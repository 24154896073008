.bookmarklists {
    &__item {
        @include card();
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;

        & + & {
            margin-top: 1rem;
        }
    }
    &__content {
        flex: 1 0 auto;
    }
    &__link {
        display: block;
        &::after {
            @include coverall;
            content: '';
            display: block;
        }
    }
    &__title {
        @include hyphenate;
        font-size: 1.1428em; // 16px
    }
    &__actions {
        display: flex;
        gap: 1rem;
    }
}

.bookmarklists__item--disabled {
    opacity: 0.7;
    .bookmarklists__link {
        color: $gray;
    }
}
