//--------------------
// SCSS MIXINS
//--------------------

// shorthand for media-breakpoint-up
@mixin mq($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

// Custom MQ for max-widths.
// applies to all widths below the given breakpoint
// i.e. mq-down(md, (md: 768px)) = max-width: 767px
@mixin mq-down($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (max-width: ($min - 1px)) {
            @content;
        }
    } @else {
        @content;
    }
}

// make container sizes
@mixin make-container-max-widths(
    $max-widths: $container-max-widths,
    $breakpoints: $grid-breakpoints
) {
    @each $breakpoint, $container-max-width in $max-widths {
        @include mq($breakpoint, $breakpoints) {
            max-width: $container-max-width;
        }
    }
}

//Hover
@mixin hover() {
    &:hover {
        @content;
    }
}

//Hover-Focus
@mixin hover-focus() {
    &:hover,
    &:focus {
        @content;
    }
}

// Only display content to screen readers
@mixin sr-only() {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px; // Fix for https://github.com/twbs/bootstrap/issues/25686
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
@mixin sr-only-focusable() {
    &:active,
    &:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
        white-space: normal;
    }
}

@mixin card() {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba($gray-darkest, 0.3),
        0 2px 32px 0 rgba($gray-darkest, 0.05);
}

// Text Hyphenation
@mixin hyphenate() {
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
}

// Text Truncation
@mixin truncate() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin flex-center() {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin coverall() {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@mixin scrollable() {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

@mixin list-reset() {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

@mixin link-reset() {
    color: inherit !important;
    text-decoration: none;
}

@mixin button-reset() {
    border: 0;
    padding: 0;
    background-color: transparent;
}

@mixin traffic-light-states($block, $property) {
    #{$block}--low & {
        #{$property}: $value-low;
    }
    #{$block}--medium & {
        #{$property}: $value-medium;
    }
    #{$block}--high & {
        #{$property}: $value-high;
    }
    #{$block}--disabled &,
    #{$block}--undefined & {
        #{$property}: $gray-light;
    }
}
@mixin traffic-light-states-inverted($block, $property) {
    #{$block}--low & {
        #{$property}: $brand-green;
    }
    #{$block}--medium & {
        #{$property}: $brand-yellow;
    }
    #{$block}--high & {
        #{$property}: $brand-red;
    }
    #{$block}--disabled &,
    #{$block}--undefined & {
        #{$property}: $gray-light;
    }
}

@mixin staggered-transition-delay($n) {
    @for $i from 0 to $n {
        &:nth-child(#{$i + 1}) {
            transition-delay: #{$i * 50}ms !important;
        }
    }
}

@mixin observed-fade-in() {
    .is-observed & {
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.33s ease, transform 0.33s ease;
    }
    .in-view & {
        opacity: 1;
        transform: translateY(0);
    }
}

@mixin triangle($color: currentColor) {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-color: $color transparent transparent transparent;
    border-width: 10px 8px 0 8px;
}
