.checkout {
    @include mq(md) {
        padding-top: 2rem;
    }

    &__title {
        margin-bottom: 1rem;
        border-bottom: 1px solid $gray-light;
        padding-bottom: 0.25rem;
    }
    &__total {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: space-between;
    }
    &__price {
        font-size: 1.5em;
    }
    &__hint {
        color: $gray;
        text-align: right;
    }
    &__vouchernotice {
        color: $gray;
        text-align: center;
    }
    &__actions {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
    }
}
