.criteria-index {
    &__group {
        margin-bottom: var(--spacing-y);
    }
    &__title {
        display: flex;
        align-items: center;
        font-size: 1rem;
        margin-bottom: 1rem;
        padding-left: 1rem;

        > .icon {
            margin-right: 0.25rem;
        }
    }
    &__item {
        display: block;
        border-top: 1px solid $gray-light;
    }
    &__list {
        border-bottom: 1px solid $gray-light;
        margin-left: calc(var(--spacing-x) * -1);
        margin-right: calc(var(--spacing-x) * -1);
    }
    &__btn {
        @include button-reset;
        display: flex;
        align-items: center;
        width: 100%;
        text-align: left;
        padding: 0.5rem 1rem 0.5rem 3rem;

        @include hover-focus {
            background-color: rgba($gray-light, 0.5);

            .criteria-index__arrow {
                color: inherit;
            }
        }
        .criteria-index__item--active & {
            font-weight: bold;
            background-color: rgba($brand-primary, 0.15);

            @include hover-focus {
                background-color: rgba($brand-primary, 0.25);
            }
        }
    }
    &__name {
        flex: 1 0 0%;
    }
    &__value {
        padding-left: 1rem;
    }
    &__check {
        flex: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $brand-primary;
        color: #fff;
        border-radius: 50%;
        width: 1.5em;
        height: 1.5em;
        font-size: 0.75em;
        margin-left: -2.5em;
        margin-right: 1em;
    }
    &__arrow {
        color: $gray;
    }
}
