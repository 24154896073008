.selectgroup {
    &__item {
        display: flex;
        margin-bottom: 1rem;
    }
    &__remove {
        border: 0;
        background: none;
        font-size: 0.75rem;

        &:hover {
            color: $brand-primary;
        }
    }
}
