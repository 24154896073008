.tooltip {
    position: relative;

    &__content {
        display: none;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, -10px);

        background-color: $gray-darker;
        color: #fff;
        border-radius: $border-radius;
        width: 250px;
        padding: 15px;
        box-shadow: $box-shadow;

        font-family: $font-family-base;
        font-size: 0.75rem;
        text-transform: none;
        letter-spacing: auto;
        font-weight: normal;
        text-align: left;
        line-height: 1.4;

        &::after {
            @include triangle($gray-darker);
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &__trigger:hover + .tooltip__content {
        display: block;
    }
}
