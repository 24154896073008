// Alert Component

.alert {
    position: relative;
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: solid;
    padding: 0.75rem 1rem;
    text-align: left;

    .btn {
        padding: 0.75em 1em;

        &--link {
            font-size: 1em;
            text-decoration: underline;

            @include hover-focus {
                text-decoration: none;
            }
        }
    }

    .icon {
        margin-right: 0.25em;
    }

    > a {
        color: inherit;
        text-decoration: underline;

        @include hover-focus {
            text-decoration: none;
        }
    }

    // Variations
    &--info {
        color: darken($state-info, 65%);
        background-color: lighten($state-info, 2%);
        border-color: darken($state-info, 15%);
    }
    &--warning {
        color: darken($state-warning, 40%);
        background-color: lighten($state-warning, 28%);
        border-color: darken($state-warning, 15%);
    }
    &--success {
        color: darken($state-success, 18%);
        border-color: darken($state-success, 2%);
        background-color: lighten($state-success, 60%);
    }
    &--error {
        color: darken($state-error, 20%);
        border-color: $state-error;
        background-color: desaturate(lighten($state-error, 31%), 28%);
    }
}
