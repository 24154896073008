// Fund Widget Component

$fund-cell-border-color: $gray-light;
$fund-cell-border-width: 1px;

// Fund Widgets need to change their layout at different breakpoints.
// They (currently) only occur in a context where they don't take up the
// whole viewport width at desktops, so we'd really need a container query here.
// unfortunately these don't exist - so media queries have to take context into account.

.fund {
    @include card();
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    font-size: 0.875rem; // 14px
    position: relative;

    &--selected {
        outline: 1px solid $brand-primary;
    }

    // Main Layout
    &__header,
    &__criteria,
    &__actions {
        flex-basis: 100%;
        max-width: 100%;
    }
    &__sustainability,
    &__performance {
        flex-basis: 50%;
        max-width: 50%;
    }
    @media screen and (min-width: 800px), print {
        &__header {
            flex: 1 1 50%;
        }
        &__sustainability,
        &__performance {
            flex: 0 0 180px;
            max-width: 180px;
        }
    }

    // Individual Sections
    &__header {
        display: flex;
    }

    &__sustainability,
    &__performance {
        display: flex;
        flex-direction: column;
        flex: 1 0 20%;
        text-align: center;
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0 !important;

        &__spacer {
            flex: 0 0 100%;
            border-bottom: 1px solid $gray-light;
            // this is necessary because of a bug in IE11.
            // it wont calculate the space correctly when
            // margin-auto is used with justify-content.
            // so this spacer fills the area.
            // @link https://stackoverflow.com/a/37535548/9293736
            @include mq(md) {
                flex: 1 0 0%;
                border: 0;
            }
        }

        .btn {
            border: 0;
            border-radius: 0;
            padding: 1em 1.25em;
        }
    }

    // Padding and Borders
    &__cell {
        padding: 1em;
        margin: 0;
        // this really weird box-shadow mimics the border-collapse behaviour
        // of tables, so we display a 1px border between cells, regardless
        // of the widget's current layout.
        box-shadow: $fund-cell-border-width 0 0 0 $fund-cell-border-color,
            0 $fund-cell-border-width 0 0 $fund-cell-border-color,
            $fund-cell-border-width $fund-cell-border-width 0 0
                $fund-cell-border-color,
            $fund-cell-border-width 0 0 0 $fund-cell-border-color inset,
            0 $fund-cell-border-width 0 0 $fund-cell-border-color inset;
    }

    // Presentational
    &__meta {
        flex: 1 0 0%;
    }
    &__name {
        @include hyphenate;
        font-size: 1.1428em; // 16px
        margin-bottom: 0.25em;
    }
    &__isin {
        margin: 0;
    }
    &__date {
        padding-left: 1rem;
        min-height: 48px;
        display: flex;
        align-items: center;
    }
    &__checkbox {
        margin-top: -1em;
        margin-left: -1em;
        transform: translateY(2px);
    }
    &__grouplink {
        display: block;
        color: $gray-dark;
        text-decoration: underline;
        margin-top: 0.5rem;

        @include hover-focus {
            color: $gray-dark;
            text-decoration: none;
        }
    }
    &__label {
        @include hyphenate;
        font-size: 1em;
        font-family: $font-family-base;
        font-weight: normal;
        letter-spacing: 0;
    }
    &__chart {
        @include flex-center;
        flex: 1 0 auto;
        font-size: 1.1428em; // 16px
        padding: 0.5em 0;
        font-weight: 700;
    }
    &__trend {
        @include flex-center;
        position: relative;
        font-weight: 700;

        .icon {
            font-size: 1.5em;
        }

        &--positive .icon {
            color: $value-high;
        }
        &--negative .icon {
            color: $value-low;
        }
    }
}

// Print Styles
@media print {
    .fund {
        font-size: 0.75rem; //12px
        border: 1px solid $gray-lighter;
        page-break-inside: avoid;
        break-inside: avoid;

        &__chart {
            font-size: 1em;
        }

        &__actions {
            display: none;
        }
    }
}
