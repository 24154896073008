.app {
    --sidebar-width: 300px;

    @include mq(lg) {
        --sidebar-width: 90px;
    }
    @include mq(xxl) {
        --sidebar-width: 270px;
    }

    display: flex;
    min-height: 100vh;
    flex-direction: column;
    margin: 0;
    background-color: $gray-lightest;

    &__header {
        position: fixed;
        z-index: z('header');
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
    }

    &__body {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-top: var(--header-height);
        padding-left: 0;
        flex: 1 0 auto;
    }

    &__sidebar {
        position: fixed;
        z-index: z('sidebar');
        top: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        transform: translateX(-100%);
        transition: transform 0.25s $animation-curve-default;

        .nav-open & {
            transform: translateX(0);
        }
    }

    &__main {
        flex: 1 0 auto;
        padding: var(--spacing-y, 2rem) var(--spacing-x, 2rem);
        max-width: 1280px;
    }

    @include mq(lg) {
        &__body {
            padding-left: var(--sidebar-width);
        }
        &__sidebar {
            transform: translateX(0) !important;
        }
    }
}

.no-app-sidebar {
    .app__body {
        padding-left: 0;
    }
    .app__main {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}
