.streamfield {
    text-align: left;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75;

    @include mq(md) {
        font-size: 1.125rem;
    }

    [class^='block-'] {
        margin: 0 auto 1rem;
        max-width: 44rem;

        @include mq(lg) {
            margin: 0 auto 2rem;
        }
    }

    .block-image,
    .block-embed {
        max-width: 50rem;
    }

    .block-paragraph,
    .block-blockquote {
        @extend %richtext;
    }
    .block-heading {
        position: relative;

        h2,
        h3,
        h4 {
            scroll-margin-top: 1rem;
        }

        .heading-anchor {
            display: none;
        }
    }
    .block-blockquote {
        text-align: center;
    }
    .block-image {
        text-align: center;

        img {
            max-width: 100%;
            height: auto;
            object-fit: cover;
            vertical-align: bottom;
        }
        figure {
            margin: 0;
        }
        figcaption {
            font-size: 1rem;
            padding-top: 0.5rem;
            color: $gray;
            font-style: italic;
            text-align: center;
        }
    }
    .block-embed {
        > div {
            position: relative;
            height: 0;
            padding-bottom: 56.25% !important;

            > iframe {
                @include coverall;
                width: 100% !important;
                height: 100%;
                z-index: 1;
            }
        }
    }

    // 2Col Layout Block
    .block-two_columns {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        width: 100%;
        max-width: none;

        [class^='block-']:first-child {
            margin-top: 0;
        }
        [class^='block-']:last-child {
            margin-bottom: 0;
        }

        @include mq(lg) {
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
        }
    }
}
