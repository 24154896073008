// Button Component

.btn {
    display: inline-block;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 0;
    font-size: 0.875rem; //14px
    font-weight: 500;
    padding: 0.28571em 1em;
    border-radius: 3px;
    text-decoration: none;
    -webkit-appearance: none;

    &:disabled,
    &--disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.65;
    }
}

.btn--large {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
}

// Block Modifier for full-width
.btn--block {
    display: flex;
    width: 100%;

    & + & {
        margin-top: 1rem;
    }
}

.btn .icon {
    margin-left: 0;
    margin-right: 0.25em;
}

.btn--icon-end {
    .icon {
        margin-right: 0;
        margin-left: 0.25em;
    }
}

// Primary CTA Buttons
.btn--primary {
    color: #fff;
    background-color: $brand-primary;
    transition: color 0.15s linear, background-color 0.15s linear;

    @include hover-focus {
        color: #fff;
        background-color: $brand-primary-dark;
    }

    &:disabled {
        background-color: $gray !important;
    }
}

.btn--outline {
    color: $brand-primary;
    background-color: transparent;
    border: 2px solid $brand-primary;
    transition: color 0.15s linear, background-color 0.15s linear;

    @include hover-focus {
        color: #fff;
        background-color: $brand-primary;
    }
}

.btn--danger {
    color: $brand-red;
    border-color: $brand-red;

    @include hover-focus {
        background-color: $brand-red;
    }
}

.btn--secondary {
    color: $brand-primary;
    border-color: $brand-primary-dark;
    background-color: transparent;
    transition: color 0.15s linear, background-color 0.15s linear;

    @include hover-focus {
        color: $brand-primary-dark;
        background-color: rgba($brand-primary, 0.1);
    }
}

.btn--link {
    @include button-reset;
    font-weight: 400;
    color: $link-color;

    @include hover-focus {
        text-decoration: underline;
    }
}
