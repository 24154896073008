.page {
    &__header {
        background-color: $brand-blue;
        padding: var(--section-spacing) 0;

        @include mq(md) {
            text-align: center;
        }
    }
    &__title {
    }
    &__intro {
        max-width: 75ch;
        margin: 1rem auto 0;
        line-height: 1.7;

        @include mq(md) {
            font-size: 1.125rem;
        }
    }
    // &__content {
    // }
}

.page--coverage {
    .page__header .container {
        padding-bottom: 4rem;
    }
    .page__content {
        margin-top: -4rem;
    }
}
.page--subscriptions {
    .page__header .container {
        padding-bottom: 1rem;
    }
    .page__content {
        margin-top: -1rem;
    }
}
