.criterionfields {
    display: block;

    &--hidden {
        display: none;
    }

    &__header {
        text-align: center;
        margin-bottom: var(--spacing-y);
    }
    &__title {
        font-family: $font-family-display;
    }
    &__description {
        margin: 0 auto;
        max-width: 50ch;
    }
    &__modeselect {
        text-align: center;
        margin-bottom: 1rem;
    }
    &__mode {
        color: $gray;
        cursor: pointer;

        &--active {
            color: inherit;
        }
    }

    &__field {
        padding: var(--spacing-y) var(--spacing-x) calc(var(--spacing-y) / 2);
        border-top: 1px solid $gray-light;
        margin-left: calc(var(--spacing-x) * -1);
        margin-right: calc(var(--spacing-x) * -1);
    }
    &__label {
        margin-bottom: 0.25rem;
    }
    &__labelhint {
        font-size: 0.875rem;
        margin-bottom: 1rem;
    }
}
