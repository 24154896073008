$sharescale-bar-height: 20px;

.sharescale {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $gray;
    margin-bottom: 1.5rem;
    -webkit-print-color-adjust: exact;

    &__col {
        display: flex;
        flex: 0 1 50%;
        position: relative;

        &--positive {
            flex-direction: row;

            .sharescale__label {
                right: 0;
                padding-left: 0.5em;
            }
            .sharescale__bar {
                background-color: $value-high;
            }
        }
        &--negative {
            flex-direction: row-reverse;

            .sharescale__label {
                left: 0;
                padding-right: 0.5em;
            }
            .sharescale__bar {
                background-color: $value-low;
            }
        }
    }
    &__zeroline {
        width: 1px;
        height: $sharescale-bar-height * 1.5;
        background-color: $gray;
        position: relative;
        text-align: center;
        font-size: 0.75rem;

        &::after {
            content: '0';
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 1.5rem);
        }
    }
    &__bar {
        height: $sharescale-bar-height;
        position: relative;
    }
    &__label {
        font-size: 0.875rem;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        background-color: rgba(#fff, 0.6);
    }
}
