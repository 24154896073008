.hero {
    position: relative;
    padding: var(--section-spacing) 0;
    background-color: $brand-beige;

    &__title {
        margin-bottom: 1rem;
    }
    &__description {
        margin-bottom: 1rem;
        line-height: 1.75;
    }
    &__cta {
        display: inline-flex;
        align-items: center;
        .icon {
            margin-left: 0.25em;
        }
    }
    &__image {
        img {
            max-width: 100%;
            height: auto;
        }
    }

    @include mq(md) {
        &__title {
            font-size: 3rem;
        }
        &__description {
            font-size: 1.125rem;
        }
        &__inner {
            display: grid;
            align-items: center;
            grid-template-columns: 3fr 2fr;
            gap: 2rem;
        }
    }
    @include mq(lg) {
        padding: 0;

        &__title {
            font-size: 3.5rem;
        }
    }
}
