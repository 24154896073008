$modal-inner-padding: 2rem;

.modal {
    z-index: z('modal');
    background-color: #fff;
    padding: 0 $modal-inner-padding;
    width: 100%;
    margin: auto;
    max-width: 560px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
    position: relative;

    &--large {
        max-width: 960px;
    }

    &__header {
        padding-top: $modal-inner-padding;
        padding-bottom: 1rem;
    }

    &__title {
        margin-bottom: 1rem;
        text-align: center;
    }

    &__content {
        > * + * {
            margin-top: 1rem;
        }

        &--center {
            text-align: center;
        }
    }

    &__overlay {
        @include scrollable;
        position: fixed;
        z-index: z('modal') - 1;
        display: grid;

        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        margin-top: $modal-inner-padding;
        margin-left: -$modal-inner-padding;
        margin-right: -$modal-inner-padding;
        border-top: 1px solid $gray-light;
        padding: 1rem $modal-inner-padding;

        button {
            margin-left: 2rem;
            min-width: 5em;
        }
    }

    &__close {
        @include button-reset;
        position: absolute;
        top: 1rem;
        right: 1rem;

        @include hover-focus {
            color: $brand-primary;
        }
    }
}
