.accordion {
    text-align: left;

    summary {
        list-style-type: none;

        &::marker {
            display: none;
        }

        &::-webkit-details-marker {
            display: none;
        }
    }

    &__item {
        &:not(:first-child) {
            border-top: 1px solid rgba(0, 0, 0, 0.07);
        }
    }

    &__tab {
        width: 100%;
        padding: 1.25rem 1.25rem 1.25rem 0;
        text-align: left;
        transition: padding-left 0.2s $animation-curve-default;

        &:hover {
            padding-left: 0.5rem;
            cursor: pointer;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        font-size: 1.125rem;
        font-weight: 700;
        margin: 0;
    }

    &__panel {
        padding-bottom: 2rem;
        font-size: 1.125rem;
    }

    &__content {
        max-width: 45rem;
        max-width: 70ch;

        > * + * {
            margin-top: 1rem;
        }
    }

    &__indicator {
        display: block;
        color: $gray-dark;
        font-size: 1.5rem;
        transition: color 0.15s linear, transform 0.2s $animation-curve-default;
        transform: rotate(0);
        margin-left: auto;

        summary:hover & {
            color: $brand-primary;
        }
        details[open] & {
            transform: rotate(90deg);
        }
    }
}
