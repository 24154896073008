.subscription {
    @include card;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    padding: 1.5rem;
    transition: transform 0.15s ease;
    will-change: transform;

    &--selected,
    &--highlighted {
        outline: 3px solid $brand-primary;
        @include mq(md) {
            transform: scale(1.03);
        }
    }

    &__header {
        margin-bottom: 0.5rem;
    }
    &__title {
        font-size: 1.5rem;
        flex: 1 0 0%;
    }
    &__description {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    &__features {
        margin-bottom: 1rem;
        flex: 1 0 auto;
    }
    &__price {
        white-space: nowrap;

        &__amount {
            color: $brand-primary;
            font-weight: 500;
            font-size: 1.25em;
        }
        &__month {
            color: $gray;
        }
        &__tax {
            color: $gray;
            padding-left: 0.5em;
        }
    }
    &__selectbtn::after {
        content: '';
        display: block;
        @include coverall;
    }
}

.subscription--alignprice {
    @include mq(md) {
        .subscription__header {
            display: flex;
            align-items: baseline;
            gap: 1rem;
        }
    }
}

.subscriptionlist {
    display: flex;
    flex-wrap: wrap;

    &__item {
        display: flex;
        flex: 1 1 100%;
        flex-direction: column;
        padding: 0;
        margin-top: 2rem;

        &:first-child {
            margin-top: 0;
        }
    }

    @include mq(lg) {
        justify-content: center;
        flex-wrap: initial;
        margin-left: -1rem;
        margin-right: -1rem;

        .subscription {
            min-height: 350px;
        }

        &__item {
            padding: 1rem;
            margin: 0;
            flex-basis: 33.33%;

            &--large {
                flex-basis: 50%;
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}
