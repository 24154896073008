.circular-progress {
    width: 60px;
    height: 60px;
    position: relative;

    &__circle {
        width: 100%;
        height: 100%;
    }
    &__value {
        color: $body-color;
        margin: 0;
        text-align: center;
        position: absolute;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        font-weight: 700;
        letter-spacing: -0.5px;
        text-indent: -1px;
    }
    &__bar {
        @include traffic-light-states('.circular-progress', stroke);
        stroke: $body-color;
    }
    &__track {
        stroke: $gray-lighter;
    }

    &--large {
        width: 120px;
        height: 120px;
        font-size: 1.5rem;

        .circular-progress__bar,
        .circular-progress__track {
            stroke-width: 2;
        }
    }
}
