.cookienotice {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: z('content-overlay');
    background: #fff;
    padding: 1.5rem 0;
    animation-name: fadeInUp;
    animation-duration: 0.5s;
    animation-fill-mode: backwards;
    border-top: 1px solid #ddd;
    box-shadow: 0 10px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

    &__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }
    &__text {
        a {
            color: inherit;
            text-decoration: underline;

            @include hover-focus {
                text-decoration: none;
            }
        }
    }
    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;

        @include mq-down(lg) {
            padding-top: 1rem;
        }

        .btn--primary {
            font-size: 1rem;
            display: block;
            margin-right: 1rem;

            @include hover-focus {
                background-color: rgba(0, 0, 0, 0.65);
            }
        }
        .btn--link {
            font-size: 1rem;
            color: inherit;
            text-decoration: underline;

            @include hover-focus {
                text-decoration: none;
            }
        }
    }
}
