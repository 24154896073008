.chartplaceholder {
    position: relative;
    min-height: 200px;

    &__overlay {
        @include coverall;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 3;
        color: inherit;
    }
    &__content {
        display: block;
        font-size: 1rem;
        font-weight: 500;
        text-shadow: 0 0 4px white;
        max-width: 75%;

        a,
        a:hover,
        a:focus {
            color: inherit;
        }
    }
    &__bg {
        position: relative;
        z-index: 1;
        text-align: center;
        user-select: none;

        img {
            display: block;
            width: 100%;
        }

        &::after {
            @include coverall;
            content: '';
            display: block;
            background: rgba(#ffffff, 0.65);
        }
    }
}

.chartplaceholder--piechart {
    .chartplaceholder__bg img {
        margin: 2.5rem auto;
        width: 220px;
    }
}
