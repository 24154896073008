.errorcontainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 2rem;
    justify-content: center;
}

.error {
    &__image {
        margin-bottom: 2rem;
        text-align: center;
    }
    &__widget {
        max-width: 36rem;
        padding: 2rem;
        margin: 0 auto 2rem;
        text-align: center;
        background-color: #fff;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
    }
    &__title {
        font-size: 2rem;
        margin-bottom: 2rem;
    }
    &__content {
        a {
            @include hyphenate;
        }
    }
}
