// UTILITY CLASSES
// All of these have !important because they should always overrule any other style

// Layout Utils

.u-mt-1 {
    margin-top: -1rem !important;
}
.u-mt0 {
    margin-top: 0 !important;
}
.u-mt1 {
    margin-top: 1rem !important;
}
.u-mt2 {
    margin-top: 2rem !important;
}

.u-mb0 {
    margin-bottom: 0 !important;
}
.u-mb05 {
    margin-bottom: 0.5rem !important;
}
.u-mb1 {
    margin-bottom: 1rem !important;
}
.u-mb2 {
    margin-bottom: 2rem !important;
}
.u-mb4 {
    margin-bottom: 4rem !important;
}

.u-ml1 {
    margin-left: 1rem !important;
}
.u-mr1 {
    margin-right: 1rem !important;
}
.u-ml-auto {
    margin-left: auto;
}

.u-p0 {
    padding: 0 !important;
}
.u-pt0 {
    padding-top: 0 !important;
}
.u-pt1 {
    padding-top: 1rem !important;
}
.u-pb0 {
    padding-bottom: 0 !important;
}
.u-pb1 {
    padding-bottom: 1rem !important;
}
.u-pl1 {
    padding-left: 1rem !important;
}
.u-pr1 {
    padding-right: 1rem !important;
}

// Color Utilities
.u-not-available {
    opacity: 0.5;
    user-select: none;
}
.u-color-gray {
    color: $gray;
}
.u-color-body {
    color: $body-color !important;
}
.u-card {
    @include card;
}

// Text Utilities

.u-align-left {
    text-align: left !important;
}
.u-align-center {
    text-align: center !important;
}
.u-align-right {
    text-align: right !important;
}

// Display Utils

.u-flex {
    display: flex !important;
    align-items: center;
}
.u-space-between {
    justify-content: space-between;
}
.u-flex-grow {
    flex-grow: 1;
}
.u-flow-spacing {
    > * + * {
        margin-top: 1rem;
    }
}
.u-clickable {
    cursor: pointer;
}
.u-print-padding-offset {
    @media print {
        margin-left: -1rem;
        margin-right: -1rem;
    }
}

.u-mobile-only {
    @include mq(md) {
        display: none !important;
    }
}
.u-tablet-only {
    @include mq-down(md) {
        display: none !important;
    }
}
.u-desktop-only {
    @include mq-down(lg) {
        display: none !important;
    }
}
.u-screen-only {
    @media print {
        display: none !important;
    }
}
.u-print-only {
    @media not print {
        display: none !important;
    }
}
