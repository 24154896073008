// Font Face Declarations

@font-face {
    font-family: 'IBM Plex Sans';
    src: url('/static/fonts/ibm-plex/ibm-plex-sans-600.woff2') format('woff2'),
        url('/static/fonts/ibm-plex/ibm-plex-sans-600.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto/roboto-400.woff2') format('woff2'),
        url('/static/fonts/roboto/roboto-400.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto/roboto-500.woff2') format('woff2'),
        url('/static/fonts/roboto/roboto-500.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('/static/fonts/roboto/roboto-700.woff2') format('woff2'),
        url('/static/fonts/roboto/roboto-700.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
