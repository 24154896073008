.modeswitch {
    display: inline-flex;
    border: 2px solid $brand-primary;
    background-color: #fff;
    border-radius: 3px;

    &__label {
        display: block;
        padding: 0.5rem 1rem;
        min-width: 8rem;
        font-size: 0.875rem; //14px
        font-weight: 500;
        text-align: center;
        cursor: pointer;

        @include hover-focus {
            background-color: rgba($brand-primary, 0.1);
        }

        &--selected {
            background-color: $brand-primary !important;
            color: #fff;
        }
    }
    &__input {
        @include sr-only;
    }
}
