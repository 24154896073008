.footer {
    --section-spacing: 2rem;

    position: relative;
    padding: var(--section-spacing) 0;
    background-color: $gray-darkest;
    color: $gray;

    a {
        color: $gray-light;
        @include hover-focus {
            color: #fff;
        }
    }

    .dot {
        display: inline-block;
        padding: 0 0.25em;
    }

    @include mq(md) {
        --section-spacing: 4rem;
        text-align: center;
    }
}
