.criterionslider {
    &__labels {
        display: flex;
        justify-content: space-between;
        font-size: 0.75rem;
        margin-top: -6px;
    }
    &__label {
        min-width: 60px;
        color: $gray;

        &--low {
            text-align: left;
        }
        &--mid {
            text-align: center;

            @include mq-down(md) {
                display: none;
            }
        }
        &--high {
            text-align: right;
        }
    }
}
