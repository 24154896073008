@media print {
    @page {
        margin: 1cm;
    }

    html,
    body {
        width: 100%;
        min-height: 100%;
        font-size: 12px;
        overflow: visible;
        background: transparent;
    }

    *,
    *:before,
    *:after {
        color: #000;
        box-shadow: none !important;
        text-shadow: none !important;
        transition: none !important;
    }

    .container {
        max-width: none !important;
        padding-left: 0;
        padding-right: 0;
    }

    .card {
        page-break-inside: avoid;
        background: transparent;
    }

    img {
        max-width: 100% !important;
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h1,
    h2,
    h3 {
        page-break-after: avoid;
    }

    .table,
    .table tbody,
    .table td,
    .table th {
        border-color: lighten(#d7d8d9, 5%) !important;
    }

    .valuebar {
        background-color: #d7d8d9 !important;
    }

    .tooltip {
        display: none;
    }
}
